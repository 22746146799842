/* eslint-disable */
import React from 'react';
import './index.css';

export default function Home() {
    return (
        <div
            className='homepage-bgimage'
            style={{
                backgroundImage: `url("/images/Simet_logo.jpeg")`,
                height: '100vh',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}
        />
    );
}
