/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import {
    Paper,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    IconButton,
    Box,
    Typography,
    Checkbox,
    Input,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import Collapse from '@mui/material/Collapse';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import {
    SaveOutlined, CancelOutlined, DeleteForeverOutlined, DeleteForever, Add,
} from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import Popup from '../../components/Popup';
import Moment from '../../components/Moment';
import http from '../../http-common';
import { loadOperadores } from '../../redux/ducks/operadores';
import { Controls } from '../../components/controls/Controls';
import useTable from '../../components/useTable';
import PageHeader from '../../components/PageHeader';
import { setIndex } from '../../redux/ducks/sideMenuIndex';
import PagosExtraForm from './PagosExtraForm';

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    searchInput: {
        width: '75%',
    },
    newButton: {},
    refreshButton: {},
    newButton1: {
        fontSize: 14,
        lineHeight: 1,
    },
    subTable: {
        marginTop: theme.spacing(3),
        '& thead th': {
            fontWeight: '600',
            color: theme.palette.primary.main,
            backgroundColor: '#f5f5dc',
        },
        '& tbody td': {
            fontWeight: '300',
        },
        '& tbody tr:hover': {
            cursor: 'pointer',
        },
    },
}));
const headCells = [
    { id: 'collapse', label: '' },
    { id: 'operador', label: 'Operador' },
    { id: 'pago', label: 'Pago Operador' },
    { id: 'anticipos', label: 'Anticipos' },
    { id: 'otrosGastos', label: 'Ingreso Extra' },
    { id: 'deducciones', label: 'Deducciones' },
    { id: 'Total', label: 'Total' },

];

const headTramos = [
    { id: 'fechaInicio', label: 'Fecha Inicio' },
    { id: 'lugarInicio', label: 'Origen' },
    { id: 'lugarFin', label: 'Destino' },
    { id: 'pago', label: 'Pago Operador' },
    { id: 'anticipos', label: 'Anticipos' },
    { id: 'otrosGastos', label: 'Ingreso Extra' },
    { id: 'total', label: 'Total' },
    { id: 'acciones', label: '' },
];

const headDescuentos = [
    { id: 'tipoDescuento', label: 'Tipo Descuento' },
    { id: 'saldoActual', label: 'Saldo' },
    { id: 'descuento', label: 'Descuento' },
    { id: 'nuevoSaldo', label: 'Nuevo Saldo' },
    { id: 'acciones', label: '' },
];

const headPagos = [
    { id: 'tipoPago', label: 'Tipo Pago' },
    { id: 'cantidad', label: 'Cantidad' },
    { id: 'acciones', label: '' },
];
function Row({
    item, updateRecords, openForm,
}) {
    const classes = useStyles();
    const [newDescuento, setNewDescuento] = useState(0);
    const [currentEditing, setCurrentEditing] = useState('');

    const onChangeDescuento = (itm, descuento) => {
        const idx = item.descuentos.findIndex((f) => f.tipoDescuento === descuento.tipoDescuento);
        const descuentos = [
            ...item.descuentos.slice(0, idx),
            {
                ...descuento,
                descuento: Number(newDescuento),
            },
            ...item.descuentos.slice(idx + 1),
        ];
        updateRecords({
            ...item,
            totalDeducciones: descuentos.map((m) => m.descuento).reduce((prev, next) => Number(prev) + Number(next)),
            descuentos: [
                ...descuentos,
            ],
        });
    };

    const handleInputChange = (event) => {
        const idx = item.items.findIndex((f) => f.tramoId === Number(event.target.name));
        const itms = [
            ...item.items.slice(0, idx),
            {
                ...item.items[idx],
                pagar: event.target.checked,
            },
            ...item.items.slice(idx + 1),
        ];
        updateRecords({
            ...item,
            items: [
                ...itms,
            ],
        });
    };

    const setOpen = (open) => {
        updateRecords({
            ...item,
            open,
        });
    };

    const deletePago = (pago) => {
        const idx = item.pagos.findIndex((f) => f.id === pago.id);
        const itms = [
            ...item.pagos.slice(0, idx),
            ...item.pagos.slice(idx + 1),
        ];

        updateRecords({
            ...item,
            pagos: [
                ...itms,
            ],
        });
    };
    const deleteTramo = (id) => {
        const idx = item.items.findIndex((f) => f.tramoId === id);
        const itms = [
            ...item.items.slice(0, idx),
            ...item.items.slice(idx + 1),
        ];
        updateRecords({
            ...item,
            items: [
                ...itms,
            ],
        });
    };

    const addPago = () => {
        openForm(item);
    };
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label='expand item' onClick={() => setOpen(!item.open)} size='small'>
                        {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell>{item.operador}</TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.pago}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.anticipo}
                    />
                </TableCell>

                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.otrosGastos}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.totalDeducciones}
                    />
                </TableCell>
                <TableCell>
                    <NumberFormat
                        decimalScale={2}
                        displayType='text'
                        prefix='$'
                        thousandSeparator
                        value={item.pago + item.otrosGastos - item.anticipo - item.totalDeducciones}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Grid container>
                                <Typography component='div' gutterBottom variant='subtitle1'>
                                    Pagos Extra:
                                </Typography>
                                <Controls.ActionButton
                                    color='primary'
                                    onClick={() => {
                                        addPago();
                                    }}
                                >
                                    <Add fontSize='small' />
                                </Controls.ActionButton>
                            </Grid>

                            <Table aria-label='pagos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headPagos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.pagos.map((pago) => (
                                            <TableRow key={pago.id}>
                                                <TableCell>{pago.tipoPago}</TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={pago.cantidad}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Controls.ActionButton
                                                        color='primary'
                                                        onClick={() => {
                                                            deletePago(pago);
                                                        }}
                                                    >
                                                        <DeleteForever fontSize='small' />
                                                    </Controls.ActionButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Descuentos:
                            </Typography>
                            <Table aria-label='tramos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headDescuentos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.descuentos.map((descuento) => (
                                            <TableRow key={`descuentos-${descuento.tipoDescuento}-${item.key}`}>
                                                <TableCell>
                                                    {descuento.tipoDescuento}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        descuento.recurrente === true
                                                            ? '-'
                                                            : descuento.saldoActual
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        currentEditing === descuento.tipoDescuento
                                                            ? (
                                                                <Input
                                                                    onChange={(e) => setNewDescuento(e.target.value)}
                                                                    type='number'
                                                                    value={newDescuento}
                                                                />
                                                            )
                                                            : descuento.descuento
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        descuento.recurrente === true
                                                            ? '-'
                                                            : descuento.saldoActual - descuento.descuento
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        currentEditing !== descuento.tipoDescuento
                                                            ? (
                                                                <Controls.ActionButton
                                                                    color='primary'
                                                                    onClick={() => {
                                                                        setNewDescuento(descuento.descuento);
                                                                        setCurrentEditing(descuento.tipoDescuento);
                                                                    }}
                                                                >
                                                                    <EditOutlinedIcon fontSize='small' />
                                                                </Controls.ActionButton>
                                                            )

                                                            : (
                                                                <>
                                                                    <Controls.ActionButton
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            onChangeDescuento(item, descuento);
                                                                            setCurrentEditing('');
                                                                        }}
                                                                    >
                                                                        <SaveOutlined fontSize='small' />
                                                                    </Controls.ActionButton>

                                                                    <Controls.ActionButton
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            setCurrentEditing('');
                                                                        }}
                                                                    >

                                                                        <CancelOutlined fontSize='small' />
                                                                    </Controls.ActionButton>

                                                                </>
                                                            )

                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={headCells.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={item.open} timeout='auto' unmountOnExit>
                        <Box margin={1}>
                            <Typography component='div' gutterBottom variant='subtitle1'>
                                Tramos:
                            </Typography>
                            <Table aria-label='tramos' className={classes.subTable} size='small'>
                                <TableHead>
                                    <TableRow>
                                        {headTramos.map((head) => (
                                            <TableCell
                                                key={head.id}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        item.items.map((tramo) => (
                                            <TableRow key={`${item.key}-${tramo.tramoId}`}>
                                                <TableCell>
                                                    <Moment date={tramo.fechaInicio} />
                                                </TableCell>
                                                <TableCell>
                                                    {tramo.lugarInicio}
                                                </TableCell>
                                                <TableCell>
                                                    {tramo.lugarFin}
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.pagoOperador}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.anticipo}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.totalGastos}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <NumberFormat
                                                        decimalScale={2}
                                                        displayType='text'
                                                        prefix='$'
                                                        thousandSeparator
                                                        value={tramo.total}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Controls.ActionButton onClick={() => deleteTramo(tramo.tramoId)}>
                                                        <DeleteForever />
                                                    </Controls.ActionButton>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
function CollapsibleRow({ item, updateRecords, openForm }) {
    return (
        <Row
            item={item}
            openForm={openForm}
            updateRecords={updateRecords}
        />
    );
}
export default function LiquidacionOperadores() {
    const classes = useStyles();
    const [filterFn] = useState({
        fn: (items) => items,
    });

    const operadores = useSelector((state) => state.operadores.operadores);
    const [operadoresSeleccionados, setOperadoresSeleccionados] = useState([]);

    const [records, setRecords] = useState([]);

    const {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
    } = useTable(records, headCells, filterFn);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIndex(1, 'Liq'));
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadOperadores(false, false, false));
    }, [dispatch]);

    const [operador, setOperador] = useState(0);
    const [fechaInicio, setFechaInicio] = useState();
    const [fechaFin, setFechaFin] = useState();
    const [sinPagar, setSinPagar] = useState(false);
    const handleInputChange = ({ target }) => {
        setSinPagar(target.value);
    };

    const handleInputChangeFechaInicio = (e) => {
        const { value } = e.target;
        setFechaInicio(value);
    };

    const handleInputChangeFechaFin = (e) => {
        const { value } = e.target;
        setFechaFin(value);
    };

    const handleMultipleSelect = (e, newValue) => {
        setOperadoresSeleccionados(newValue);
    };

    const updateRecords = (op) => {
        const idx = records.findIndex((r) => r.key === op.key);
        op.otrosGastos = 0;
        op.pago = 0;
        op.anticipo = 0;

        op.items.forEach((e) => {
            op.otrosGastos += Number(e.cantidadOtrosGastos);
            op.pago += Number(e.pagoOperador);
            op.anticipo += Number(e.anticipo);
        });

        op.pago += op.pagos.map((m) => m.cantidad).reduce((prev, next) => Number(prev) + Number(next), 0);

        const newRecords = [
            ...records.slice(0, idx),
            op,
            ...records.slice(idx + 1),
        ];
        setRecords(newRecords);
    };

    const actualizar = () => {
        setRecords([]);
        if ((fechaInicio && fechaFin) || sinPagar) {
            const mapOperadores = operadoresSeleccionados.map((m) => m.id);
            let url = `/viajes/liquidacionOperador?sinPagar=${sinPagar}`;
            if (!sinPagar) {
                url += `&fechaInicial=${fechaInicio?.toISOString(true)}&fechaFinal=${fechaFin?.toISOString(true)}`;
            }
            return http.post(
                url,
                mapOperadores,
            )
                .then((data) => {
                    const map = data.data;

                    const d = [];
                    Object.keys(map).forEach((key) => {
                        d.push({
                            key,
                            id: uuidv4(),
                            open: true,
                            fechaPago: new Date().toLocaleDateString(),
                            descuentos: map[key][0].descuentos,
                            pagos: [],
                            totalDeducciones: map[key].map((m) => m.totalDescuentos)[0],
                            operador: operadores.filter((f) => f.id === Number(key)).map((p) => `${p.nombre} ${p.apellidos} `)[0],
                            items: map[key],
                            pago: map[key].map((m) => m.pagoOperador)
                                .reduce((prev, next) => prev + next, 0),
                            anticipo: map[key].map((m) => m.anticipo)
                                .reduce((prev, next) => prev + next),
                            otrosGastos: map[key].map((m) => m.cantidadOtrosGastos)
                                .reduce((prev, next) => prev + next),
                        });
                    });

                    d.forEach((o) => {
                        o.items.forEach((t) => {
                            if (t.gastos.length > 0) {
                            // eslint-disable-next-line no-param-reassign
                                t.totalGastos = t.gastos.map((g) => g.cantidad)
                                    .reduce((prev, next) => prev + next);
                            } else {
                                t.totalGastos = 0.0;
                            }
                            t.total = t.pagoOperador - t.anticipo + t.totalGastos;
                            t.pagar = true;
                        });
                    });
                    setRecords([...d]);
                });
        }
        return null;
    };

    const guardar = async () => {
        records.forEach((r) => {
            http.post(`/operadores/${r.key}/guardarPago`, r);
        });
    };

    const cargarGuardados = async () => {
        const mapOperadores = operadoresSeleccionados.map((m) => m.id);
        const data = await http.post('/operadores/obtenerGuardados', mapOperadores);
        if (data) {
            setRecords(data.data);
        }
    };
    const pagar = async () => {
        const tramosAPagar = [];
        records.forEach((o) => {
            tramosAPagar.push(...o.items.filter((f) => f.pagar).map((m) => ({
                ...m,
                descuentos: o.descuentos,
            })));
        });
        const payload = tramosAPagar.map((t) => ({
            tramoId: t.tramoId,
            operadorId: t.operadorId,
            descuentos: t.descuentos,
            todo: records.filter((f) => Number(f.key) === t.operadorId)[0],
        }));

        const response = await http.post('/viajes/pagoOperador', payload);
        if (response.status === 200) {
            actualizar();
        }
    };

    const [openPopup, setOpenPopup] = useState(false);
    const [operadorActual, setOperadorActual] = useState();
    const openForm = (item) => {
        setOperadorActual(item);
        setOpenPopup(true);
    };

    const addOrEdit = (op) => {
        console.log(op, operadorActual);
        updateRecords({
            ...operadorActual,
            pagos: [
                ...operadorActual.pagos,
                {
                    ...op,
                    id: uuidv4(),
                },
            ],
        });
        setOpenPopup(false);
    };
    return (
        <>
            <Box displayPrint='none'>
                <PageHeader
                    icon={<PeopleOutlineTwoToneIcon fontSize='large' />}
                    subtitle=''
                    title='Liquidacion Operadores'
                />
            </Box>
            <Paper className={classes.pageContent}>
                <Box displayPrint='none'>
                    <Grid
                        container
                        justifyContent='flex-start'
                        spacing={1}
                    >

                        <Grid item xs={3}>
                            <Autocomplete
                                getOptionLabel={(option) => `${option.nombre} ${option.apellidos}`}
                                id='tags-standard'
                                multiple
                                onChange={handleMultipleSelect}
                                options={operadores}
                                renderInput={(params) => (
                                    <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label='Operadores'
                                        placeholder='Operadores'
                                        variant='standard'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            <Controls.Checkbox
                                checked={sinPagar}
                                label='Pendientes de pago'
                                name='sinPagar'
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.DatePicker
                                label='Fecha Inicio Viaje'
                                name='fechaInicio'
                                onChange={handleInputChangeFechaInicio}
                                value={fechaInicio}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Controls.DatePicker
                                label='Fecha Fin Viaje'
                                name='fechaFin'
                                onChange={handleInputChangeFechaFin}
                                value={fechaFin}
                            />
                        </Grid>
                        <Grid container item spacing={1} xs={24}>
                            <Grid item xs={2}>
                                <Controls.Button
                                    className={classes.newButton1}
                                    onClick={() => {
                                        actualizar();
                                    }}
                                    text='Actualizar'
                                    variant='outlined'

                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Controls.Button
                                    className={classes.newButton1}
                                    onClick={() => {
                                        pagar();
                                    }}
                                    text='Pagar'
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Controls.Button
                                    className={classes.newButton1}
                                    onClick={() => {
                                        guardar();
                                    }}
                                    text='Guardar'
                                    variant='outlined'
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <Controls.Button
                                    className={classes.newButton1}
                                    onClick={() => {
                                        cargarGuardados();
                                    }}
                                    text='Cargar Guardado'
                                    variant='outlined'
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
                <TblContainer>
                    <TblHead />
                    <TableBody>
                        {recordsAfterPagingAndSorting().map((item) => <CollapsibleRow key={item.key} item={item} openForm={openForm} updateRecords={updateRecords} />)}
                    </TableBody>
                </TblContainer>
                <TblPagination />
            </Paper>
            <Popup
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
                title='Cliente'
            >
                <PagosExtraForm
                    addOrEdit={addOrEdit}
                />
            </Popup>
        </>
    );
}
